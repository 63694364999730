// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");

var setImageSmoothing = function (ctx,v){
       ctx.mozImageSmoothingEnabled = v;
       ctx.oImageSmoothingEnabled = v;
       ctx.webkitImageSmoothingEnabled = v;
       ctx.msImageSmoothingEnabled = v;
       ctx.imageSmoothingEnabled = v;
       };

var Ctx = /* module */[/* setImageSmoothing */setImageSmoothing];

function initImageData(ctx, w, h, f) {
  var imageData = ctx.createImageData(w, h);
  var rawData = imageData.data;
  for(var y = 0 ,y_finish = h - 1 | 0; y <= y_finish; ++y){
    for(var x = 0 ,x_finish = w - 1 | 0; x <= x_finish; ++x){
      var offset = Caml_int32.imul(y, (w << 2)) + (x << 2) | 0;
      var match = Curry._2(f, x, y);
      Caml_array.caml_array_set(rawData, offset, match[0]);
      Caml_array.caml_array_set(rawData, offset + 1 | 0, match[1]);
      Caml_array.caml_array_set(rawData, offset + 2 | 0, match[2]);
      Caml_array.caml_array_set(rawData, offset + 3 | 0, match[3]);
    }
  }
  return imageData;
}

var Util = /* module */[/* initImageData */initImageData];

var component = ReasonReact.statelessComponent("Canvas");

function make(setRef, width, height, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              return React.createElement("canvas", {
                          ref: setRef,
                          height: height.toString(),
                          width: width.toString()
                        });
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.Ctx = Ctx;
exports.Util = Util;
exports.component = component;
exports.make = make;
/* component Not a pure module */
