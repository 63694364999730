// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';


function circShiftL(value, count) {
  var count$1 = count & 7;
  return (value << count$1) & 255 | (value >>> (-count$1 & 7)) & 255;
}

function circShiftR(value, count) {
  var count$1 = count & 7;
  return (value >>> count$1) & 255 | (value << (-count$1 & 7)) & 255;
}

var mask = 7;

exports.mask = mask;
exports.circShiftL = circShiftL;
exports.circShiftR = circShiftR;
/* No side effect */
