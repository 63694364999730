// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Canvas = require("./Canvas");
var Random = require("bs-platform/lib/js/random.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");

function Make(ProgrammaticImage) {
  var f = ProgrammaticImage[0];
  var draw = function (input, width, height, theRef) {
    if (theRef == null) {
      return /* () */0;
    } else {
      var ctx = theRef.getContext("2d");
      var imageData = Canvas.Util[/* initImageData */0](ctx, width, height, Curry._3(f, input, width, height));
      ctx.putImageData(imageData, 0, 0);
      return /* () */0;
    }
  };
  var component = ReasonReact.statelessComponent("ProgrammaticImage");
  var make = function (setRef, width, height, input, _) {
    return /* record */[
            /* debugName */component[/* debugName */0],
            /* reactClassInternal */component[/* reactClassInternal */1],
            /* handedOffState */component[/* handedOffState */2],
            /* willReceiveProps */component[/* willReceiveProps */3],
            /* didMount */component[/* didMount */4],
            /* didUpdate */component[/* didUpdate */5],
            /* willUnmount */component[/* willUnmount */6],
            /* willUpdate */component[/* willUpdate */7],
            /* shouldUpdate */component[/* shouldUpdate */8],
            /* render */(function () {
                return ReasonReact.element(undefined, undefined, Canvas.make((function (theRef) {
                                  draw(input, width, height, theRef);
                                  return Curry._1(setRef, theRef);
                                }), width, height, /* array */[]));
              }),
            /* initialState */component[/* initialState */10],
            /* retainedProps */component[/* retainedProps */11],
            /* reducer */component[/* reducer */12],
            /* jsElementWrapped */component[/* jsElementWrapped */13]
          ];
  };
  return /* module */[
          /* f */f,
          /* draw */draw,
          /* component */component,
          /* make */make
        ];
}

var component = ReasonReact.statelessComponent("Img");

function make(setRef, src, width, height, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              return React.createElement("img", {
                          ref: (function (theRef) {
                              if (theRef == null) {
                                return /* () */0;
                              } else {
                                theRef.onload = (function () {
                                    return Curry._1(setRef, theRef);
                                  });
                                return /* () */0;
                              }
                            }),
                          height: height.toString(),
                          src: src,
                          width: width.toString()
                        });
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

var Img = /* module */[
  /* component */component,
  /* make */make
];

var component$1 = ReasonReact.statelessComponent("StaticCanvas");

function make$1(setRef, draw, width, height, _) {
  return /* record */[
          /* debugName */component$1[/* debugName */0],
          /* reactClassInternal */component$1[/* reactClassInternal */1],
          /* handedOffState */component$1[/* handedOffState */2],
          /* willReceiveProps */component$1[/* willReceiveProps */3],
          /* didMount */component$1[/* didMount */4],
          /* didUpdate */component$1[/* didUpdate */5],
          /* willUnmount */component$1[/* willUnmount */6],
          /* willUpdate */component$1[/* willUpdate */7],
          /* shouldUpdate */component$1[/* shouldUpdate */8],
          /* render */(function () {
              return React.createElement("canvas", {
                          ref: (function (theRef) {
                              if (theRef == null) {
                                return /* () */0;
                              } else {
                                Curry._3(draw, width, height, theRef);
                                return Curry._1(setRef, theRef);
                              }
                            }),
                          height: height.toString(),
                          width: width.toString()
                        });
            }),
          /* initialState */component$1[/* initialState */10],
          /* retainedProps */component$1[/* retainedProps */11],
          /* reducer */component$1[/* reducer */12],
          /* jsElementWrapped */component$1[/* jsElementWrapped */13]
        ];
}

var StaticCanvas = /* module */[
  /* component */component$1,
  /* make */make$1
];

function f(param, _, _$1, _$2, _$3) {
  return /* tuple */[
          param[0],
          param[1],
          param[2],
          255
        ];
}

function draw(input, width, height, theRef) {
  if (theRef == null) {
    return /* () */0;
  } else {
    var ctx = theRef.getContext("2d");
    var imageData = Canvas.Util[/* initImageData */0](ctx, width, height, (function (param, param$1) {
            return f(input, width, height, param, param$1);
          }));
    ctx.putImageData(imageData, 0, 0);
    return /* () */0;
  }
}

var component$2 = ReasonReact.statelessComponent("ProgrammaticImage");

function make$2(setRef, width, height, input, _) {
  return /* record */[
          /* debugName */component$2[/* debugName */0],
          /* reactClassInternal */component$2[/* reactClassInternal */1],
          /* handedOffState */component$2[/* handedOffState */2],
          /* willReceiveProps */component$2[/* willReceiveProps */3],
          /* didMount */component$2[/* didMount */4],
          /* didUpdate */component$2[/* didUpdate */5],
          /* willUnmount */component$2[/* willUnmount */6],
          /* willUpdate */component$2[/* willUpdate */7],
          /* shouldUpdate */component$2[/* shouldUpdate */8],
          /* render */(function () {
              return ReasonReact.element(undefined, undefined, Canvas.make((function (theRef) {
                                draw(input, width, height, theRef);
                                return Curry._1(setRef, theRef);
                              }), width, height, /* array */[]));
            }),
          /* initialState */component$2[/* initialState */10],
          /* retainedProps */component$2[/* retainedProps */11],
          /* reducer */component$2[/* reducer */12],
          /* jsElementWrapped */component$2[/* jsElementWrapped */13]
        ];
}

var Fill = /* module */[
  /* f */f,
  /* draw */draw,
  /* component */component$2,
  /* make */make$2
];

function f$1(_, _$1, _$2, x, y) {
  var v = x ^ y;
  return /* tuple */[
          v,
          v,
          v,
          255
        ];
}

function draw$1(input, width, height, theRef) {
  if (theRef == null) {
    return /* () */0;
  } else {
    var ctx = theRef.getContext("2d");
    var imageData = Canvas.Util[/* initImageData */0](ctx, width, height, (function (param, param$1) {
            return f$1(input, width, height, param, param$1);
          }));
    ctx.putImageData(imageData, 0, 0);
    return /* () */0;
  }
}

var component$3 = ReasonReact.statelessComponent("ProgrammaticImage");

function make$3(setRef, width, height, input, _) {
  return /* record */[
          /* debugName */component$3[/* debugName */0],
          /* reactClassInternal */component$3[/* reactClassInternal */1],
          /* handedOffState */component$3[/* handedOffState */2],
          /* willReceiveProps */component$3[/* willReceiveProps */3],
          /* didMount */component$3[/* didMount */4],
          /* didUpdate */component$3[/* didUpdate */5],
          /* willUnmount */component$3[/* willUnmount */6],
          /* willUpdate */component$3[/* willUpdate */7],
          /* shouldUpdate */component$3[/* shouldUpdate */8],
          /* render */(function () {
              return ReasonReact.element(undefined, undefined, Canvas.make((function (theRef) {
                                draw$1(input, width, height, theRef);
                                return Curry._1(setRef, theRef);
                              }), width, height, /* array */[]));
            }),
          /* initialState */component$3[/* initialState */10],
          /* retainedProps */component$3[/* retainedProps */11],
          /* reducer */component$3[/* reducer */12],
          /* jsElementWrapped */component$3[/* jsElementWrapped */13]
        ];
}

var XorTexture = /* module */[
  /* f */f$1,
  /* draw */draw$1,
  /* component */component$3,
  /* make */make$3
];

function f$2(_, _$1, _$2, x, y) {
  var v = (x ^ y) % 3;
  if (v > 2 || v < 0) {
    return /* tuple */[
            192,
            192,
            64,
            255
          ];
  } else {
    switch (v) {
      case 0 : 
          return /* tuple */[
                  64,
                  192,
                  192,
                  255
                ];
      case 1 : 
          return /* tuple */[
                  192,
                  64,
                  192,
                  255
                ];
      case 2 : 
          return /* tuple */[
                  192,
                  192,
                  64,
                  255
                ];
      
    }
  }
}

function draw$2(input, width, height, theRef) {
  if (theRef == null) {
    return /* () */0;
  } else {
    var ctx = theRef.getContext("2d");
    var imageData = Canvas.Util[/* initImageData */0](ctx, width, height, (function (param, param$1) {
            return f$2(input, width, height, param, param$1);
          }));
    ctx.putImageData(imageData, 0, 0);
    return /* () */0;
  }
}

var component$4 = ReasonReact.statelessComponent("ProgrammaticImage");

function make$4(setRef, width, height, input, _) {
  return /* record */[
          /* debugName */component$4[/* debugName */0],
          /* reactClassInternal */component$4[/* reactClassInternal */1],
          /* handedOffState */component$4[/* handedOffState */2],
          /* willReceiveProps */component$4[/* willReceiveProps */3],
          /* didMount */component$4[/* didMount */4],
          /* didUpdate */component$4[/* didUpdate */5],
          /* willUnmount */component$4[/* willUnmount */6],
          /* willUpdate */component$4[/* willUpdate */7],
          /* shouldUpdate */component$4[/* shouldUpdate */8],
          /* render */(function () {
              return ReasonReact.element(undefined, undefined, Canvas.make((function (theRef) {
                                draw$2(input, width, height, theRef);
                                return Curry._1(setRef, theRef);
                              }), width, height, /* array */[]));
            }),
          /* initialState */component$4[/* initialState */10],
          /* retainedProps */component$4[/* retainedProps */11],
          /* reducer */component$4[/* reducer */12],
          /* jsElementWrapped */component$4[/* jsElementWrapped */13]
        ];
}

var XorFractal = /* module */[
  /* f */f$2,
  /* draw */draw$2,
  /* component */component$4,
  /* make */make$4
];

function f$3(param, width, height, x, y) {
  var match = param[/* color */1];
  var radius = param[/* radius */0];
  var centerX = width / 2 | 0;
  var centerY = height / 2 | 0;
  var radiusSquared = Caml_int32.imul(radius, radius);
  var cX = x - centerX | 0;
  var cY = y - centerY | 0;
  var inCircle = Pervasives.abs((Caml_int32.imul(cX, cX) + Caml_int32.imul(cY, cY) | 0) - radiusSquared | 0) < radius;
  if (inCircle) {
    return /* tuple */[
            match[0],
            match[1],
            match[2],
            255
          ];
  } else {
    return /* tuple */[
            0,
            0,
            0,
            255
          ];
  }
}

function draw$3(input, width, height, theRef) {
  if (theRef == null) {
    return /* () */0;
  } else {
    var ctx = theRef.getContext("2d");
    var imageData = Canvas.Util[/* initImageData */0](ctx, width, height, (function (param, param$1) {
            return f$3(input, width, height, param, param$1);
          }));
    ctx.putImageData(imageData, 0, 0);
    return /* () */0;
  }
}

var component$5 = ReasonReact.statelessComponent("ProgrammaticImage");

function make$5(setRef, width, height, input, _) {
  return /* record */[
          /* debugName */component$5[/* debugName */0],
          /* reactClassInternal */component$5[/* reactClassInternal */1],
          /* handedOffState */component$5[/* handedOffState */2],
          /* willReceiveProps */component$5[/* willReceiveProps */3],
          /* didMount */component$5[/* didMount */4],
          /* didUpdate */component$5[/* didUpdate */5],
          /* willUnmount */component$5[/* willUnmount */6],
          /* willUpdate */component$5[/* willUpdate */7],
          /* shouldUpdate */component$5[/* shouldUpdate */8],
          /* render */(function () {
              return ReasonReact.element(undefined, undefined, Canvas.make((function (theRef) {
                                draw$3(input, width, height, theRef);
                                return Curry._1(setRef, theRef);
                              }), width, height, /* array */[]));
            }),
          /* initialState */component$5[/* initialState */10],
          /* retainedProps */component$5[/* retainedProps */11],
          /* reducer */component$5[/* reducer */12],
          /* jsElementWrapped */component$5[/* jsElementWrapped */13]
        ];
}

var Circle = /* module */[
  /* f */f$3,
  /* draw */draw$3,
  /* component */component$5,
  /* make */make$5
];

function f$4(input, _, _$1, x, y) {
  return /* tuple */[
          x,
          y,
          input,
          255
        ];
}

function draw$4(input, width, height, theRef) {
  if (theRef == null) {
    return /* () */0;
  } else {
    var ctx = theRef.getContext("2d");
    var imageData = Canvas.Util[/* initImageData */0](ctx, width, height, (function (param, param$1) {
            return f$4(input, width, height, param, param$1);
          }));
    ctx.putImageData(imageData, 0, 0);
    return /* () */0;
  }
}

var component$6 = ReasonReact.statelessComponent("ProgrammaticImage");

function make$6(setRef, width, height, input, _) {
  return /* record */[
          /* debugName */component$6[/* debugName */0],
          /* reactClassInternal */component$6[/* reactClassInternal */1],
          /* handedOffState */component$6[/* handedOffState */2],
          /* willReceiveProps */component$6[/* willReceiveProps */3],
          /* didMount */component$6[/* didMount */4],
          /* didUpdate */component$6[/* didUpdate */5],
          /* willUnmount */component$6[/* willUnmount */6],
          /* willUpdate */component$6[/* willUpdate */7],
          /* shouldUpdate */component$6[/* shouldUpdate */8],
          /* render */(function () {
              return ReasonReact.element(undefined, undefined, Canvas.make((function (theRef) {
                                draw$4(input, width, height, theRef);
                                return Curry._1(setRef, theRef);
                              }), width, height, /* array */[]));
            }),
          /* initialState */component$6[/* initialState */10],
          /* retainedProps */component$6[/* retainedProps */11],
          /* reducer */component$6[/* reducer */12],
          /* jsElementWrapped */component$6[/* jsElementWrapped */13]
        ];
}

var RGGamut = /* module */[
  /* f */f$4,
  /* draw */draw$4,
  /* component */component$6,
  /* make */make$6
];

function f$5(input, _, _$1, x, y) {
  return /* tuple */[
          x,
          input,
          y,
          255
        ];
}

function draw$5(input, width, height, theRef) {
  if (theRef == null) {
    return /* () */0;
  } else {
    var ctx = theRef.getContext("2d");
    var imageData = Canvas.Util[/* initImageData */0](ctx, width, height, (function (param, param$1) {
            return f$5(input, width, height, param, param$1);
          }));
    ctx.putImageData(imageData, 0, 0);
    return /* () */0;
  }
}

var component$7 = ReasonReact.statelessComponent("ProgrammaticImage");

function make$7(setRef, width, height, input, _) {
  return /* record */[
          /* debugName */component$7[/* debugName */0],
          /* reactClassInternal */component$7[/* reactClassInternal */1],
          /* handedOffState */component$7[/* handedOffState */2],
          /* willReceiveProps */component$7[/* willReceiveProps */3],
          /* didMount */component$7[/* didMount */4],
          /* didUpdate */component$7[/* didUpdate */5],
          /* willUnmount */component$7[/* willUnmount */6],
          /* willUpdate */component$7[/* willUpdate */7],
          /* shouldUpdate */component$7[/* shouldUpdate */8],
          /* render */(function () {
              return ReasonReact.element(undefined, undefined, Canvas.make((function (theRef) {
                                draw$5(input, width, height, theRef);
                                return Curry._1(setRef, theRef);
                              }), width, height, /* array */[]));
            }),
          /* initialState */component$7[/* initialState */10],
          /* retainedProps */component$7[/* retainedProps */11],
          /* reducer */component$7[/* reducer */12],
          /* jsElementWrapped */component$7[/* jsElementWrapped */13]
        ];
}

var RBGamut = /* module */[
  /* f */f$5,
  /* draw */draw$5,
  /* component */component$7,
  /* make */make$7
];

function f$6(input, _, _$1, x, y) {
  return /* tuple */[
          input,
          x,
          y,
          255
        ];
}

function draw$6(input, width, height, theRef) {
  if (theRef == null) {
    return /* () */0;
  } else {
    var ctx = theRef.getContext("2d");
    var imageData = Canvas.Util[/* initImageData */0](ctx, width, height, (function (param, param$1) {
            return f$6(input, width, height, param, param$1);
          }));
    ctx.putImageData(imageData, 0, 0);
    return /* () */0;
  }
}

var component$8 = ReasonReact.statelessComponent("ProgrammaticImage");

function make$8(setRef, width, height, input, _) {
  return /* record */[
          /* debugName */component$8[/* debugName */0],
          /* reactClassInternal */component$8[/* reactClassInternal */1],
          /* handedOffState */component$8[/* handedOffState */2],
          /* willReceiveProps */component$8[/* willReceiveProps */3],
          /* didMount */component$8[/* didMount */4],
          /* didUpdate */component$8[/* didUpdate */5],
          /* willUnmount */component$8[/* willUnmount */6],
          /* willUpdate */component$8[/* willUpdate */7],
          /* shouldUpdate */component$8[/* shouldUpdate */8],
          /* render */(function () {
              return ReasonReact.element(undefined, undefined, Canvas.make((function (theRef) {
                                draw$6(input, width, height, theRef);
                                return Curry._1(setRef, theRef);
                              }), width, height, /* array */[]));
            }),
          /* initialState */component$8[/* initialState */10],
          /* retainedProps */component$8[/* retainedProps */11],
          /* reducer */component$8[/* reducer */12],
          /* jsElementWrapped */component$8[/* jsElementWrapped */13]
        ];
}

var GBGamut = /* module */[
  /* f */f$6,
  /* draw */draw$6,
  /* component */component$8,
  /* make */make$8
];

function f$7(input, _, _$1, x, y) {
  if (x === 0 && y === 0) {
    Random.init(input);
  }
  return /* tuple */[
          Random.$$int(256),
          Random.$$int(256),
          Random.$$int(256),
          255
        ];
}

function draw$7(input, width, height, theRef) {
  if (theRef == null) {
    return /* () */0;
  } else {
    var ctx = theRef.getContext("2d");
    var imageData = Canvas.Util[/* initImageData */0](ctx, width, height, (function (param, param$1) {
            return f$7(input, width, height, param, param$1);
          }));
    ctx.putImageData(imageData, 0, 0);
    return /* () */0;
  }
}

var component$9 = ReasonReact.statelessComponent("ProgrammaticImage");

function make$9(setRef, width, height, input, _) {
  return /* record */[
          /* debugName */component$9[/* debugName */0],
          /* reactClassInternal */component$9[/* reactClassInternal */1],
          /* handedOffState */component$9[/* handedOffState */2],
          /* willReceiveProps */component$9[/* willReceiveProps */3],
          /* didMount */component$9[/* didMount */4],
          /* didUpdate */component$9[/* didUpdate */5],
          /* willUnmount */component$9[/* willUnmount */6],
          /* willUpdate */component$9[/* willUpdate */7],
          /* shouldUpdate */component$9[/* shouldUpdate */8],
          /* render */(function () {
              return ReasonReact.element(undefined, undefined, Canvas.make((function (theRef) {
                                draw$7(input, width, height, theRef);
                                return Curry._1(setRef, theRef);
                              }), width, height, /* array */[]));
            }),
          /* initialState */component$9[/* initialState */10],
          /* retainedProps */component$9[/* retainedProps */11],
          /* reducer */component$9[/* reducer */12],
          /* jsElementWrapped */component$9[/* jsElementWrapped */13]
        ];
}

var PseudoRandom = /* module */[
  /* f */f$7,
  /* draw */draw$7,
  /* component */component$9,
  /* make */make$9
];

function f$8(input, _, _$1, x, y) {
  if (x === 0 && y === 0) {
    Random.init(input);
  }
  var v = Random.$$int(256);
  return /* tuple */[
          v,
          v,
          v,
          255
        ];
}

function draw$8(input, width, height, theRef) {
  if (theRef == null) {
    return /* () */0;
  } else {
    var ctx = theRef.getContext("2d");
    var imageData = Canvas.Util[/* initImageData */0](ctx, width, height, (function (param, param$1) {
            return f$8(input, width, height, param, param$1);
          }));
    ctx.putImageData(imageData, 0, 0);
    return /* () */0;
  }
}

var component$10 = ReasonReact.statelessComponent("ProgrammaticImage");

function make$10(setRef, width, height, input, _) {
  return /* record */[
          /* debugName */component$10[/* debugName */0],
          /* reactClassInternal */component$10[/* reactClassInternal */1],
          /* handedOffState */component$10[/* handedOffState */2],
          /* willReceiveProps */component$10[/* willReceiveProps */3],
          /* didMount */component$10[/* didMount */4],
          /* didUpdate */component$10[/* didUpdate */5],
          /* willUnmount */component$10[/* willUnmount */6],
          /* willUpdate */component$10[/* willUpdate */7],
          /* shouldUpdate */component$10[/* shouldUpdate */8],
          /* render */(function () {
              return ReasonReact.element(undefined, undefined, Canvas.make((function (theRef) {
                                draw$8(input, width, height, theRef);
                                return Curry._1(setRef, theRef);
                              }), width, height, /* array */[]));
            }),
          /* initialState */component$10[/* initialState */10],
          /* retainedProps */component$10[/* retainedProps */11],
          /* reducer */component$10[/* reducer */12],
          /* jsElementWrapped */component$10[/* jsElementWrapped */13]
        ];
}

var PseudoRandomGray = /* module */[
  /* f */f$8,
  /* draw */draw$8,
  /* component */component$10,
  /* make */make$10
];

exports.Make = Make;
exports.Img = Img;
exports.StaticCanvas = StaticCanvas;
exports.Fill = Fill;
exports.XorTexture = XorTexture;
exports.XorFractal = XorFractal;
exports.Circle = Circle;
exports.RGGamut = RGGamut;
exports.RBGamut = RBGamut;
exports.GBGamut = GBGamut;
exports.PseudoRandom = PseudoRandom;
exports.PseudoRandomGray = PseudoRandomGray;
/* component Not a pure module */
