// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Bits = require("./Bits");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Canvas = require("./Canvas");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Js_primitive = require("bs-platform/lib/js/js_primitive.js");
var Belt_MapString = require("bs-platform/lib/js/belt_MapString.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var ImageGenerator = require("./ImageGenerator");

function addToMap(key, map, theRef) {
  if (theRef == null) {
    return /* () */0;
  } else {
    map[0] = Belt_MapString.set(map[0], key, theRef);
    return /* () */0;
  }
}

function setCanvasRef(width, height, theRef, param) {
  var state = param[/* state */1];
  state[/* canvasRef */0][0] = (theRef == null) ? undefined : Js_primitive.some(theRef);
  addToMap("self", state[/* images */1], theRef);
  var match = state[/* canvasRef */0][0];
  if (match !== undefined) {
    var ctx = Js_primitive.valFromOption(match).getContext("2d", {
          alpha: false
        });
    Canvas.Ctx[/* setImageSmoothing */0](ctx, false);
    ctx.fillStyle = "black";
    ctx.fillRect(0, 0, width, height);
    return /* () */0;
  } else {
    return /* () */0;
  }
}

function transformCanvas(f, width, height, canvas) {
  var ctx = canvas.getContext("2d");
  var imageData = ctx.getImageData(0, 0, width, height);
  Curry._1(f, imageData.data);
  ctx.putImageData(imageData, 0, 0);
  return /* () */0;
}

function rotate(f, width, height, param, param$1) {
  var b = param[2];
  var g = param[1];
  var r = param[0];
  var match = param$1[/* state */1][/* canvasRef */0][0];
  if (match !== undefined) {
    return transformCanvas((function (rawNew) {
                  var n = rawNew.length / 4 | 0;
                  for(var i = 0 ,i_finish = n - 1 | 0; i <= i_finish; ++i){
                    var offset = (i << 2);
                    Caml_array.caml_array_set(rawNew, offset, Curry._2(f, Caml_array.caml_array_get(rawNew, offset), r));
                    Caml_array.caml_array_set(rawNew, offset + 1 | 0, Curry._2(f, Caml_array.caml_array_get(rawNew, offset + 1 | 0), g));
                    Caml_array.caml_array_set(rawNew, offset + 2 | 0, Curry._2(f, Caml_array.caml_array_get(rawNew, offset + 2 | 0), b));
                  }
                  return /* () */0;
                }), width, height, Js_primitive.valFromOption(match));
  } else {
    return /* () */0;
  }
}

function rotateL(param, param$1, param$2, param$3) {
  return rotate(Bits.circShiftL, param, param$1, param$2, param$3);
}

function rotateR(param, param$1, param$2, param$3) {
  return rotate(Bits.circShiftR, param, param$1, param$2, param$3);
}

var setHref = function (el,v){el.href = v;};

function draw(width, height, imageKey, param) {
  var state = param[/* state */1];
  var match = state[/* canvasRef */0][0];
  var match$1 = Belt_MapString.get(state[/* images */1][0], imageKey);
  if (match !== undefined && match$1 !== undefined) {
    var canvas = Js_primitive.valFromOption(match);
    var ctx = canvas.getContext("2d");
    var imageData = ctx.getImageData(0, 0, width, height);
    var rawOld = imageData.data;
    var n = imageData.data.length / 4 | 0;
    ctx.drawImage(Js_primitive.valFromOption(match$1), 0, 0, width, height);
    return transformCanvas((function (rawNew) {
                  for(var i = 0 ,i_finish = n - 1 | 0; i <= i_finish; ++i){
                    var offset = (i << 2);
                    Caml_array.caml_array_set(rawNew, offset, Caml_array.caml_array_get(rawOld, offset) ^ Caml_array.caml_array_get(rawNew, offset));
                    Caml_array.caml_array_set(rawNew, offset + 1 | 0, Caml_array.caml_array_get(rawOld, offset + 1 | 0) ^ Caml_array.caml_array_get(rawNew, offset + 1 | 0));
                    Caml_array.caml_array_set(rawNew, offset + 2 | 0, Caml_array.caml_array_get(rawOld, offset + 2 | 0) ^ Caml_array.caml_array_get(rawNew, offset + 2 | 0));
                    Caml_array.caml_array_set(rawNew, offset + 3 | 0, Caml_primitive.caml_int_max(Caml_array.caml_array_get(rawOld, offset + 3 | 0), Caml_array.caml_array_get(rawNew, offset + 3 | 0)));
                  }
                  return /* () */0;
                }), width, height, canvas);
  } else {
    return /* () */0;
  }
}

var component = ReasonReact.statelessComponent("Clickable");

function make(render, name, onClick, $staropt$star, $staropt$star$1, _) {
  var outerStyle = $staropt$star !== undefined ? Js_primitive.valFromOption($staropt$star) : ({
        height: "128px",
        width: "128px"
      });
  var innerStyle = $staropt$star$1 !== undefined ? Js_primitive.valFromOption($staropt$star$1) : ({
        transform: "scale(0.5)",
        transformOrigin: "top left"
      });
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              return React.createElement("div", {
                          style: outerStyle,
                          onClick: (function () {
                              return Curry._1(onClick, name);
                            })
                        }, React.createElement("div", {
                              style: innerStyle
                            }, Curry._1(render, name)));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

var Clickable = /* module */[
  /* component */component,
  /* make */make
];

var component$1 = ReasonReact.statelessComponent("RotationControl");

function make$1(_, rotationF, label, color, _$1) {
  return /* record */[
          /* debugName */component$1[/* debugName */0],
          /* reactClassInternal */component$1[/* reactClassInternal */1],
          /* handedOffState */component$1[/* handedOffState */2],
          /* willReceiveProps */component$1[/* willReceiveProps */3],
          /* didMount */component$1[/* didMount */4],
          /* didUpdate */component$1[/* didUpdate */5],
          /* willUnmount */component$1[/* willUnmount */6],
          /* willUpdate */component$1[/* willUpdate */7],
          /* shouldUpdate */component$1[/* shouldUpdate */8],
          /* render */(function () {
              return React.createElement("button", {
                          style: {
                            background: "none",
                            border: "none",
                            color: color,
                            fontSize: "24px",
                            margin: "0px",
                            padding: "0px",
                            textAlign: "center",
                            flexGrow: "1"
                          },
                          onClick: (function () {
                              return Curry._1(rotationF, /* () */0);
                            })
                        }, label);
            }),
          /* initialState */component$1[/* initialState */10],
          /* retainedProps */component$1[/* retainedProps */11],
          /* reducer */component$1[/* reducer */12],
          /* jsElementWrapped */component$1[/* jsElementWrapped */13]
        ];
}

var RotationControl = /* module */[
  /* component */component$1,
  /* make */make$1
];

var component$2 = ReasonReact.reducerComponent("XorCanvas");

function make$2($staropt$star, $staropt$star$1, _) {
  var width = $staropt$star !== undefined ? $staropt$star : 256;
  var height = $staropt$star$1 !== undefined ? $staropt$star$1 : 256;
  return /* record */[
          /* debugName */component$2[/* debugName */0],
          /* reactClassInternal */component$2[/* reactClassInternal */1],
          /* handedOffState */component$2[/* handedOffState */2],
          /* willReceiveProps */component$2[/* willReceiveProps */3],
          /* didMount */component$2[/* didMount */4],
          /* didUpdate */component$2[/* didUpdate */5],
          /* willUnmount */component$2[/* willUnmount */6],
          /* willUpdate */component$2[/* willUpdate */7],
          /* shouldUpdate */component$2[/* shouldUpdate */8],
          /* render */(function (self) {
              var onClick = function (name) {
                return draw(width, height, name, self);
              };
              var rotationPanel = function (f, label) {
                return React.createElement("div", {
                            style: {
                              display: "flex",
                              flexDirection: "column",
                              flexGrow: "1"
                            }
                          }, ReasonReact.element(undefined, undefined, make$1(height, (function () {
                                      return Curry._4(f, width, height, /* tuple */[
                                                  1,
                                                  0,
                                                  0
                                                ], self);
                                    }), label, "red", /* array */[])), ReasonReact.element(undefined, undefined, make$1(height, (function () {
                                      return Curry._4(f, width, height, /* tuple */[
                                                  0,
                                                  1,
                                                  0
                                                ], self);
                                    }), label, "green", /* array */[])), ReasonReact.element(undefined, undefined, make$1(height, (function () {
                                      return Curry._4(f, width, height, /* tuple */[
                                                  0,
                                                  0,
                                                  1
                                                ], self);
                                    }), label, "blue", /* array */[])), ReasonReact.element(undefined, undefined, make$1(height, (function () {
                                      return Curry._4(f, width, height, /* tuple */[
                                                  1,
                                                  1,
                                                  1
                                                ], self);
                                    }), label, "white", /* array */[])));
              };
              var rotationPanelL = rotationPanel(rotateL, "←");
              var rotationPanelR = rotationPanel(rotateR, "→");
              return React.createElement("div", undefined, React.createElement("div", {
                              style: {
                                background: "rgba(0,0,0,0.9)",
                                display: "flex",
                                position: "fixed",
                                width: "100vw",
                                zIndex: "1",
                                flexDirection: "column"
                              }
                            }, React.createElement("div", {
                                  style: {
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between"
                                  }
                                }, rotationPanelL, ReasonReact.element(undefined, undefined, make((function () {
                                            return React.createElement("canvas", {
                                                        ref: Curry._1(self[/* handle */0], (function (param, param$1) {
                                                                return setCanvasRef(width, height, param, param$1);
                                                              })),
                                                        style: {
                                                          border: "1px solid white"
                                                        },
                                                        height: height.toString(),
                                                        width: width.toString()
                                                      });
                                          }), "self", onClick, {
                                          background: "black",
                                          width: width.toString() + "px"
                                        }, { }, /* array */[])), rotationPanelR), React.createElement("div", {
                                  style: {
                                    display: "flex",
                                    fontSize: "24px",
                                    flexDirection: "row",
                                    justifyContent: "space-between"
                                  }
                                }, React.createElement("a", {
                                      style: {
                                        color: "white",
                                        textAlign: "center",
                                        textDecoration: "none",
                                        width: "100vw"
                                      },
                                      download: "xor-pics.png",
                                      onClick: (function (evt) {
                                          var match = self[/* state */1][/* canvasRef */0][0];
                                          if (match !== undefined) {
                                            var el = evt.target;
                                            var dataURL = Js_primitive.valFromOption(match).toDataURL("image/png");
                                            return setHref(el, dataURL);
                                          } else {
                                            return /* () */0;
                                          }
                                        })
                                    }, React.createElement("svg", {
                                          style: {
                                            pointerEvents: "none"
                                          },
                                          height: "24",
                                          width: "24",
                                          viewBox: "0 0 24 24",
                                          xmlns: "http://www.w3.org/2000/svg"
                                        }, React.createElement("path", {
                                              d: "M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z",
                                              fill: "#fff"
                                            }))))), React.createElement("div", {
                              style: {
                                position: "relative",
                                top: "320px"
                              }
                            }, React.createElement("div", {
                                  style: {
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    justifyContent: "center"
                                  }
                                }, ReasonReact.element(undefined, undefined, make((function (name) {
                                            return ReasonReact.element(undefined, undefined, ImageGenerator.Img[/* make */1]((function (theRef) {
                                                              addToMap(name, self[/* state */1][/* images */1], theRef);
                                                              var match = self[/* state */1][/* canvasRef */0][0];
                                                              if (match !== undefined && !(theRef == null)) {
                                                                var ctx = Js_primitive.valFromOption(match).getContext("2d");
                                                                ctx.drawImage(theRef, 0, 0);
                                                                return /* () */0;
                                                              } else {
                                                                return /* () */0;
                                                              }
                                                            }), "images/logo.png", width, height, /* array */[]));
                                          }), "logo", onClick, undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, make((function (name) {
                                            var partial_arg = self[/* state */1][/* images */1];
                                            return ReasonReact.element(undefined, undefined, ImageGenerator.Fill[/* make */3]((function (param) {
                                                              return addToMap(name, partial_arg, param);
                                                            }), width, height, /* tuple */[
                                                            63,
                                                            63,
                                                            63
                                                          ], /* array */[]));
                                          }), "gray-25", onClick, undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, make((function (name) {
                                            var partial_arg = self[/* state */1][/* images */1];
                                            return ReasonReact.element(undefined, undefined, ImageGenerator.Fill[/* make */3]((function (param) {
                                                              return addToMap(name, partial_arg, param);
                                                            }), width, height, /* tuple */[
                                                            127,
                                                            127,
                                                            127
                                                          ], /* array */[]));
                                          }), "gray-50", onClick, undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, make((function (name) {
                                            var partial_arg = self[/* state */1][/* images */1];
                                            return ReasonReact.element(undefined, undefined, ImageGenerator.Fill[/* make */3]((function (param) {
                                                              return addToMap(name, partial_arg, param);
                                                            }), width, height, /* tuple */[
                                                            191,
                                                            191,
                                                            191
                                                          ], /* array */[]));
                                          }), "gray-75", onClick, undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, make((function (name) {
                                            var partial_arg = self[/* state */1][/* images */1];
                                            return ReasonReact.element(undefined, undefined, ImageGenerator.Fill[/* make */3]((function (param) {
                                                              return addToMap(name, partial_arg, param);
                                                            }), width, height, /* tuple */[
                                                            255,
                                                            255,
                                                            255
                                                          ], /* array */[]));
                                          }), "white", onClick, undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, make((function (name) {
                                            var partial_arg = self[/* state */1][/* images */1];
                                            return ReasonReact.element(undefined, undefined, ImageGenerator.Circle[/* make */3]((function (param) {
                                                              return addToMap(name, partial_arg, param);
                                                            }), width, height, /* record */[
                                                            /* radius */20,
                                                            /* color : tuple */[
                                                              255,
                                                              255,
                                                              255
                                                            ]
                                                          ], /* array */[]));
                                          }), "white-circle", onClick, undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, make((function (name) {
                                            var partial_arg = self[/* state */1][/* images */1];
                                            return ReasonReact.element(undefined, undefined, ImageGenerator.XorTexture[/* make */3]((function (param) {
                                                              return addToMap(name, partial_arg, param);
                                                            }), width, height, /* () */0, /* array */[]));
                                          }), "xor-texture", onClick, undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, make((function (name) {
                                            var partial_arg = self[/* state */1][/* images */1];
                                            return ReasonReact.element(undefined, undefined, ImageGenerator.Fill[/* make */3]((function (param) {
                                                              return addToMap(name, partial_arg, param);
                                                            }), width, height, /* tuple */[
                                                            191,
                                                            0,
                                                            0
                                                          ], /* array */[]));
                                          }), "red", onClick, undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, make((function (name) {
                                            var partial_arg = self[/* state */1][/* images */1];
                                            return ReasonReact.element(undefined, undefined, ImageGenerator.Fill[/* make */3]((function (param) {
                                                              return addToMap(name, partial_arg, param);
                                                            }), width, height, /* tuple */[
                                                            0,
                                                            191,
                                                            0
                                                          ], /* array */[]));
                                          }), "green", onClick, undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, make((function (name) {
                                            var partial_arg = self[/* state */1][/* images */1];
                                            return ReasonReact.element(undefined, undefined, ImageGenerator.Fill[/* make */3]((function (param) {
                                                              return addToMap(name, partial_arg, param);
                                                            }), width, height, /* tuple */[
                                                            0,
                                                            0,
                                                            191
                                                          ], /* array */[]));
                                          }), "blue", onClick, undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, make((function (name) {
                                            var partial_arg = self[/* state */1][/* images */1];
                                            return ReasonReact.element(undefined, undefined, ImageGenerator.GBGamut[/* make */3]((function (param) {
                                                              return addToMap(name, partial_arg, param);
                                                            }), width, height, 191, /* array */[]));
                                          }), "gb", onClick, undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, make((function (name) {
                                            var partial_arg = self[/* state */1][/* images */1];
                                            return ReasonReact.element(undefined, undefined, ImageGenerator.RBGamut[/* make */3]((function (param) {
                                                              return addToMap(name, partial_arg, param);
                                                            }), width, height, 191, /* array */[]));
                                          }), "rb", onClick, undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, make((function (name) {
                                            var partial_arg = self[/* state */1][/* images */1];
                                            return ReasonReact.element(undefined, undefined, ImageGenerator.RGGamut[/* make */3]((function (param) {
                                                              return addToMap(name, partial_arg, param);
                                                            }), width, height, 191, /* array */[]));
                                          }), "rg", onClick, undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, make((function (name) {
                                            var partial_arg = self[/* state */1][/* images */1];
                                            return ReasonReact.element(undefined, undefined, ImageGenerator.XorFractal[/* make */3]((function (param) {
                                                              return addToMap(name, partial_arg, param);
                                                            }), width, height, /* () */0, /* array */[]));
                                          }), "xor-fractal", onClick, undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, make((function (name) {
                                            var partial_arg = self[/* state */1][/* images */1];
                                            return ReasonReact.element(undefined, undefined, ImageGenerator.Img[/* make */1]((function (param) {
                                                              return addToMap(name, partial_arg, param);
                                                            }), "images/floral_shoppe.jpg", width, height, /* array */[]));
                                          }), "floral", onClick, undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, make((function (name) {
                                            var partial_arg = self[/* state */1][/* images */1];
                                            return ReasonReact.element(undefined, undefined, ImageGenerator.Img[/* make */1]((function (param) {
                                                              return addToMap(name, partial_arg, param);
                                                            }), "images/planes.png", width, height, /* array */[]));
                                          }), "planes", onClick, undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, make((function (name) {
                                            var partial_arg = self[/* state */1][/* images */1];
                                            return ReasonReact.element(undefined, undefined, ImageGenerator.Img[/* make */1]((function (param) {
                                                              return addToMap(name, partial_arg, param);
                                                            }), "images/koko_and_winnie.jpg", width, height, /* array */[]));
                                          }), "cats", onClick, undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, make((function (name) {
                                            var partial_arg = self[/* state */1][/* images */1];
                                            return ReasonReact.element(undefined, undefined, ImageGenerator.Img[/* make */1]((function (param) {
                                                              return addToMap(name, partial_arg, param);
                                                            }), "images/mandrill.png", width, height, /* array */[]));
                                          }), "mandrill", onClick, undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, make((function (name) {
                                            var partial_arg = self[/* state */1][/* images */1];
                                            return ReasonReact.element(undefined, undefined, ImageGenerator.PseudoRandom[/* make */3]((function (param) {
                                                              return addToMap(name, partial_arg, param);
                                                            }), width, height, 0, /* array */[]));
                                          }), "random-rgb", onClick, undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, make((function (name) {
                                            var partial_arg = self[/* state */1][/* images */1];
                                            return ReasonReact.element(undefined, undefined, ImageGenerator.Img[/* make */1]((function (param) {
                                                              return addToMap(name, partial_arg, param);
                                                            }), "images/koko_and_winnie_hidden.png", width, height, /* array */[]));
                                          }), "cats-concealed", onClick, undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, make((function (name) {
                                            var partial_arg = self[/* state */1][/* images */1];
                                            return ReasonReact.element(undefined, undefined, ImageGenerator.Img[/* make */1]((function (param) {
                                                              return addToMap(name, partial_arg, param);
                                                            }), "images/ich_ruf_zu_dir.png", width, height, /* array */[]));
                                          }), "ich_ruf_zu_dir", onClick, undefined, undefined, /* array */[])))));
            }),
          /* initialState */(function () {
              return /* record */[
                      /* canvasRef : record */[/* contents */undefined],
                      /* images : record */[/* contents */Belt_MapString.empty]
                    ];
            }),
          /* retainedProps */component$2[/* retainedProps */11],
          /* reducer */(function (_, _$1) {
              return /* NoUpdate */0;
            }),
          /* jsElementWrapped */component$2[/* jsElementWrapped */13]
        ];
}

exports.addToMap = addToMap;
exports.setCanvasRef = setCanvasRef;
exports.transformCanvas = transformCanvas;
exports.rotate = rotate;
exports.rotateL = rotateL;
exports.rotateR = rotateR;
exports.setHref = setHref;
exports.draw = draw;
exports.Clickable = Clickable;
exports.RotationControl = RotationControl;
exports.component = component$2;
exports.make = make$2;
/* component Not a pure module */
